import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Toggle from 'react-toggle'

import FormInput from '../../common/Form-Input/FormInput'
import Textarea from '../../common/Textarea/Textarea'
import Button from '../../common/Button/Button'
import VienRadio from '../../common/Radio/Radio'
import { validateNotification, validatePassChange } from '../../../validations/settings'
import {
  sendNotification
} from '../../../actions/dashboardActions'
import './styles.scss'
import { api_url } from '../../../utils/api'
import { axiosInstance } from '../../../actions/authActions'

class Notification extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: '',
      title: '',
      message: '',
      both: true,
      walker: false,
      owner: false,
      individual: false,
      other: true,
      update: false,
      sendto: 'both',
      loading: false
    }
  }

  componentDidMount () {
    const token = localStorage.getItem('jwtToken')
    sessionStorage.setItem('walkerCurrentPage', '1')
    sessionStorage.setItem('bookingCurrentPage', '1')
    sessionStorage.setItem('ownerCurrentPage', '1')
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  sendNotificationMail = (e) => {
    e.preventDefault()
    const { sendto, email, both, individual, walker, owner, title, message } = this.state
    const result = validateNotification(
      sendto,
      email,
      title,
      message
    )
    if (result) return toast.error(result)
    this.setState({
      loading: true
    })
    const token = localStorage.getItem('jwtToken')
    axiosInstance
      .post(
        api_url + '/api/admin/send/notification',
        this.state,
        {
          headers: { 'x-access-token': token }
        }
      )
      .then((res) => {
        this.setState({
          loading: false
        })
        if (res.data.success) {
          toast.success(res.data.msg)
          this.setState({
            email: '',
            title: '',
            message: ''
          })
        }
        if (res.data.error) return toast.error(res.data.error)
      })
      .catch((err) => {
        this.setState({
          loading: false
        })
        console.log(err)
      })
  }

  handleRadioChange = (value) => {
    this.setState({
      both: value === 'both',
      walker: value === 'walker',
      owner: value === 'owner',
      individual: value === 'individual',
      sendto: value

    })
  }

  handleNotificationType = (value) => {
    this.setState({
      other: value === 'other',
      update: value === 'update',
      type: value === 'other' ? 'Other' : 'Update'
    })
  }

  render () {
    return (
      <div className='container-fluid settings'>
        <div className='row mx-0'>
          <div className='col-md-12 px-0'>
            <h3 className='text-left'>Notification</h3>
          </div>
        </div>

        <div className='change-password text-left'>
          <h4>Send Notification</h4>
          <div className='form-group'>
            <label className='admin'>Send to ?</label>
            <VienRadio
              name='both'
              text='Both'
              checked={this.state.both}
              handleChange={() => this.handleRadioChange('both')}
            />
            <VienRadio
              name='walker'
              text='Service Provider'
              checked={this.state.walker}
              handleChange={() => this.handleRadioChange('walker')}
            />
            <VienRadio
              name='owner'
              text='Owner'
              checked={this.state.owner}
              handleChange={() => this.handleRadioChange('owner')}
            />
            <VienRadio
              name='individual'
              text='Individual'
              checked={this.state.individual}
              handleChange={() => this.handleRadioChange('individual')}
            />
          </div>
          <form onSubmit={this.sendNotificationMail}>
            {this.state.individual
              ? <FormInput
                  icon='fa fa-envelope-o icon'
                  type='text'
                  name='email'
                  placeholder='Send To : Email'
                  value={this.state.email}
                  handleChange={this.handleChange}
                />
              : null}

            <FormInput
              icon='fa fa-comment icon'
              type='text'
              name='title'
              placeholder='Title'
              value={this.state.title}
              handleChange={this.handleChange}
            />

            <Textarea
              icon='fa fa-comment icon'
              type='description'
              name='message'
              placeholder='Message'
              rows={4}
              className='notificationTextArea'
              value={this.state.message}
              handleChange={this.handleChange}
            />

            <Button
              type='submit'
              value='Submit'
              loading={this.state.loading}
              className='btn btn-dark shadow-none change-pass-btn'
            />
          </form>
        </div>
      </div>
    )
  }
}

Notification.propTypes = {
  dashboard: PropTypes.object.isRequired,
  sendNotification: PropTypes.func.isRequired

}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard
})

export default connect(mapStateToProps, {
  sendNotification
})(withRouter(Notification))
