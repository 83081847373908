import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import Table from './Table'
import Pagination from '../../common/Pagination/Pagination'
import Search from '../../common/Search/Search'
import AddWalkerModal from '../../common/Modal/AddWalker'
import DeleteModal from '../../common/Modal/DeleteModal'
import Button from '../../common/Button/Button'
import { validateAddWalker } from '../../../validations/add-walker'
import {
  unblockWalker,
  createWalker,
  deleteWalker,
  getAllWalkers,
  searchWalkers,
  approveWalker,
  ensureWalker,
  accpetWalkerRequest
} from '../../../actions/dashboardActions'
import './styles.scss'
import ExportToExcel from '../../common/Modal/ExportToExcel'

class ManageWalker extends Component {
  constructor() {
    super()
    this.state = {
      id: '',
      query: '',
      currentPage: 1,
      pageLimit: 20,
      showDeleteModal: false,
      showAddWalkerModal: false,
      title: '',
      fullName: '',
      email: '',
      phoneNumber: '',
      code: '',
      walkers: [],
      showExportModal: false,
      columnArray: []
    }
    this.searchTimeout = null;
  }

  componentDidMount() {
    const token = localStorage.getItem('jwtToken')
    const data = sessionStorage.getItem('walkerCurrentPage') || this.state.currentPage
    this.setState({ currentPage: parseInt(data) })
    this.props.getAllWalkers(
      token,
      data,
      this.state.pageLimit,
      ""
    ) // Fetch data
  }

  addWalkerModalClicked = () => {
    this.setState({ showAddWalkerModal: true })
  }

  handleAddWalkerModalClose = () => {
    this.setState({ showAddWalkerModal: false })
  }

  // Block user
  handleUnblockUser = (id) => {
    const token = localStorage.getItem('jwtToken')
    this.props.unblockWalker(id, token, this.state.currentPage,
      this.state.pageLimit)
    this.setState({ showDeleteModal: false })
  }

  // change approve status
  handleApproveUser = (id) => {
    const token = localStorage.getItem('jwtToken')
    this.props.approveWalker(id, token, this.state.currentPage,
      this.state.pageLimit)
  }

  // change ensure status
  handleEnsureUser = (id) => {
    const token = localStorage.getItem('jwtToken')
    this.props.ensureWalker(id, token, this.state.currentPage,
      this.state.pageLimit)
  }

  // Handle Pagination
  handlePageClick = (page) => {
    const token = localStorage.getItem('jwtToken')
    this.setState({ currentPage: page })
    this.props.getAllWalkers(token, page, this.state.pageLimit, "")
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  // Search for user
  handleSearch = (query) => {
    const token = localStorage.getItem('jwtToken')
    this.setState({ query })
    this.setState({ currentPage: 1 })
    this.props.getAllWalkers(token, 1, this.state.pageLimit, query ? query : "")
    // this.props.searchWalkers(query, token)
  }

  handleSearch2 = (query) => {
    this.setState({ query, currentPage: 1 });

    // Clear the previous timeout if it's still active
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    // Set a new timeout to delay the API call
    this.searchTimeout = setTimeout(() => {
      this.handleSearchAPI(query);
    }, 1000); // 1-second delay
  }

  handleSearchAPI = (query) => {
    const token = localStorage.getItem('jwtToken');
    this.props.getAllWalkers(token, 1, this.state.pageLimit, query ? query : "");
  }

  // Delete walker
  deleteUser = (id) => {
    const token = localStorage.getItem('jwtToken')
    this.props.deleteWalker(id, token)
    this.props.getAllWalkers(
      token,
      this.state.currentPage,
      this.state.pageLimit,
      ""
    )
  }

  // open delete confirmation modal
  deleteModalClicked = (id) => {
    this.setState({ showDeleteModal: true, id })
  }

  exportModalClicked = () => {
    const array = [
      { label: "Name", key: "basicInfo.fullName" },
      { label: "Email", key: "basicInfo.email" },
      { label: "Profile status", key: "basicInfo.image", isCheck: true, if: "Complete", else: "Incomplete" },
      { label: "Average rating", key: "rating" },
      { label: "Date registered", key: "createdAt", date: true },
      { label: "Hourly rate", key: "fee" },
      { label: "Active", key: "basicInfo.isUserBlocked", isCheck: true, if: "Suspended", else: "Active" },
      { label: "Approval", key: "isApproved", isCheck: true, if: "Approve", else: "Reject" },
      { label: "Insurance", key: "isEnsure", isCheck: true, if: "Insure", else: "Not Insure" },
    ]
    this.setState({ columnArray: array, showExportModal: true })
  }

  acceptrejectModalClick = async (data, currentPage) => {
    await this.props.accpetWalkerRequest(data, currentPage);
  }

  handleDeleteModalClose = () => {
    this.setState({ showDeleteModal: false })
  }

  handleYesBtnClick = () => {
    const token = localStorage.getItem('jwtToken')
    this.props.deleteWalker(this.state.id, token)
    this.setState({ showDeleteModal: false })
    this.props.getAllWalkers(
      token,
      this.state.currentPage,
      this.state.pageLimit,
      ""
    )
  }

  handleNoBtnClick = () => {
    this.setState({ showDeleteModal: false })
  }

  addWalkerModalClicked = () => {
    this.setState({ showAddWalkerModal: true })
  }

  // Add new walker
  handleAddWalkerSubmit = (e) => {
    e.preventDefault()
    const token = localStorage.getItem('jwtToken')
    const { fullName, email, phoneNumber, currentPage, pageLimit } = this.state

    const result = validateAddWalker(fullName, email, phoneNumber)
    if (result) return toast.error(result)

    const walker = {
      email,
      phoneNumber,
      fullName,
      pageLimit,
      page: currentPage
    }

    this.props.createWalker(walker, token)

    this.setState({ showAddWalkerModal: false })
  }


  render() {
    const currentPageLength =
      this.props.dashboard.allWalkers && this.props.dashboard.allWalkers.length

    const totalWalkers = this.props.dashboard.allWalkers.length || 0
    const totalNoOfWalker = sessionStorage.getItem('total_no_walkers') || 0
    const currentRecordsFrom =
      this.state.pageLimit * (this.state.currentPage - 1) + 1
    const currentRecordsTo = currentRecordsFrom + currentPageLength - 1

    return (
      <>
        <div className='manage-doctor-wrapper manage_walkers_page'>
          <h5 className='text-left'>Manage Service Providers</h5>
          <div className='row mx-0'>
            <div className='col-md-6 pl-0 search_col'>
              <Search
                icon='fa fa-search icon'
                size='15px'
                placeholder='Search Service Provider'
                search={this.state.query}
                handleSearch={this.handleSearch2}
              />
            </div>
            <div className='col-md-6 pr-0 right_col'>
              {/* <Button
                type="button"
                className="btn btn-primary add-new-user-btn shadow-none"
                value="Add Walker"
                handleClick={this.addWalkerModalClicked}
              /> */}
              <Button
                type="button"
                className="btn btn-primary export-btn shadow-none"
                value="Export"
                handleClick={this.exportModalClicked}
              />
            </div>
          </div>
          <div className='manage-doctor custom_class'>
            <div className='table-responsive'>
              <Table
                walkers={this.props.dashboard.allWalkers}
                handleBlockUser={this.handleBlockUser}
                handleApproveUser={this.handleApproveUser}
                handleEnsureUser={this.handleEnsureUser}
                handleUnblockUser={this.handleUnblockUser}
                accessRole={this.props.accessRole}
                deleteModalClicked={this.deleteModalClicked}
                classname='dashboard_table'
                currentPage={this.state.currentPage}
                acceptrejectModalClick={this.acceptrejectModalClick}
              />
            </div>
          </div>

          <div className='row result_row mx-0'>
            <div className='col-md-6 px-0 left_col'>
              {/* {!this.state.query && ( */}
                {this.props.dashboard.allWalkers?.length ? <h6 className='results'>
                  Showing {currentRecordsFrom} - {currentRecordsTo} results of{' '}
                  {totalNoOfWalker}
                </h6> : ""}
              {/* )} */}
            </div>
            <div className='col-md-6 px-0 right_col'>
              {/* {!this.state.query && ( */}
                {this.props.dashboard.allWalkers?.length ? <Pagination
                  count={totalWalkers}
                  totalItem={totalNoOfWalker}
                  pageLimit={this.state.pageLimit}
                  currentPage={this.state.currentPage}
                  handlePageClick={this.handlePageClick}
                /> : ""}
              {/* )} */}
            </div>
          </div>
        </div>


        {this.state.showExportModal && <ExportToExcel handleClose={() => this.setState({ showExportModal: false })} show={this.state.showExportModal} columnList={this.state.columnArray} apiUrl={'/api/admin/get/selected-service-provider'} />}

        <AddWalkerModal
          showAddWalkerModal={this.state.showAddWalkerModal}
          handleAddWalkerModalClose={this.handleAddWalkerModalClose}
          handleAddWalkerSubmit={this.handleAddWalkerSubmit}
          handleChange={this.handleChange}
          title={this.state.title}
          fullName={this.state.fullName}
          email={this.state.email}
          phoneNumber={this.state.phoneNumber}
          code={this.state.code}
        />
        <DeleteModal
          showDeleteModal={this.state.showDeleteModal}
          handleDeleteModalClose={this.handleDeleteModalClose}
          title='Delete Account'
          text="Are you sure you want to delete this service provider's account?"
          handleYesBtnClick={this.handleYesBtnClick}
          handleNoBtnClick={this.handleNoBtnClick}
        />
      </>
    )
  }
}

ManageWalker.propTypes = {
  dashboard: PropTypes.object.isRequired,
  unblockWalker: PropTypes.func.isRequired,
  createWalker: PropTypes.func.isRequired,
  deleteWalker: PropTypes.func.isRequired,
  getAllWalkers: PropTypes.func.isRequired,
  searchWalkers: PropTypes.func.isRequired,
  approveWalker: PropTypes.func.isRequired,
  ensureWalker: PropTypes.func.isRequired,
  accpetWalkerRequest: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  accessRole: state.dashboard.accessRole,
})

export default connect(mapStateToProps, {
  unblockWalker,
  createWalker,
  deleteWalker,
  getAllWalkers,
  searchWalkers,
  approveWalker,
  ensureWalker,
  accpetWalkerRequest
})(ManageWalker)
